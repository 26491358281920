import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
  a {
    cursor: pointer;
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("COVER_S.png");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-900 opacity-25`;
const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block h-2/3`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-2xl text-center lg:text-left sm:text-3xl lg:text-4xl xl:text-5xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export default function HeroHeader(props) {
  function goTo(id) {
    if (showNavLinks)
      toggleNavbar();

    props.goTo(id);
  }

  const navLinks = [
    <NavLinks key={1}>
      <NavLink onClick={() => goTo("Different")}>
        Distinctive
      </NavLink>
      <NavLink onClick={() => goTo("Stats")}>
        KPI's
      </NavLink>
      <NavLink onClick={() => goTo("CloudLockers")}>
        Smart
      </NavLink>
      <NavLink onClick={() => goTo("App")}>
        App
      </NavLink>
      <NavLink onClick={() => goTo("FullSolution")}>
        Entire Solutions
      </NavLink>
      <NavLink onClick={() => goTo("Worldmap")}>
        Locations
      </NavLink>
      <NavLink onClick={() => goTo("FAQS")}>
        FAQS
      </NavLink>
      <NavLink onClick={() => window.open("http://blog.mylock.es", "_self")}>
        News
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink onClick={() => goTo("contact")}>
        Contact us
      </PrimaryLink>
    </NavLinks>
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn>
            <Notification>Transform your workspace with the <b>smartest lockers</b> in the market</Notification>
            <Heading>
              <span>SMART LOCKER</span>
              <br />
              <SlantedBackground>SOLUTIONS</SlantedBackground>
            </Heading>
            {/*<PrimaryAction>Read Customer Stories</PrimaryAction>*/}
          </LeftColumn>

        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
