import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import ProfileThreeColGridCards from "components/cards/ProfileThreeColGrid.js"
import DownloadApp from "components/cta/DownloadApp.js";
import FAQ from "components/faqs/SingleCol.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import Footer from "components/footers/FiveColumnDark.js";
import ThreeColumnWithProfileImage from "components/testimonials/ThreeColumnWithProfileImage.js"
import TwoTrendingPreviewCardsWithImage from "components/cards/TwoTrendingPreviewCardsWithImage.js"
const sleep = ms => new Promise(r => setTimeout(r, ms));

export default function App() {
  const [animation, setAnimation] = React.useState(true);

  async function goTo(id) {
    console.log(id)
    setAnimation(false);

    var element = document.getElementById("Different");
    element.scrollIntoView({ behavior: 'smooth'});
    if (id === "Different") return;
    
    await sleep(100);
    element = document.getElementById("CloudLockers");
    element.scrollIntoView({ behavior: 'smooth'});
    if (id === "CloudLockers") return;
    
    await sleep(100);
    element = document.getElementById("Stats");
    element.scrollIntoView({ behavior: 'smooth'});
    if (id === "Stats") return;

    await sleep(100);
    element = document.getElementById("FullSolution");
    element.scrollIntoView({ behavior: 'smooth'});
    if (id === "FullSolution") return;

    await sleep(100);
    element = document.getElementById("App");
    element.scrollIntoView({ behavior: 'smooth'});
    if (id === "App") return;

    await sleep(100);
    element = document.getElementById("FAQS");
    element.scrollIntoView({ behavior: 'smooth'});
    if (id === "FAQS") return;

    await sleep(100);
    element = document.getElementById("Worldmap");
    element.scrollIntoView({ behavior: 'smooth'});
    if (id === "Worldmap") return;

    await sleep(100);
    element = document.getElementById("contact");
    element.scrollIntoView({ behavior: 'smooth'});
    if (id === "contact") return;

    element = document.getElementById("about");
    element.scrollIntoView({ behavior: 'smooth'});

    setAnimation(true);
  }

  async function contact(email, name, message) {
    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({email, subject: name, message}),
            headers: { 'Content-Type': 'application/json' },
            mode: 'no-cors', // no-cors, *cors, same-origin
        };

        fetch('https://europe-west1-mylock-541a9.cloudfunctions.net/contactWeb', requestOptions)
        .then(res => {
          console.log(res);
          return { code: 400, response: res };
        })
        .catch(err => {
          console.log(err);
          return { error: '<contact> error: ' + err, code: 401 };
        });
    }
    catch (e) {
        console.log("<contact> error: ", e);
        return { error: '<contact> error: ' + e, code: 401 };
    }
}

  return (
    <Router>
      <Switch>
        <Route path="/">
          <AnimationRevealPage disabled={!animation}>
            <Hero goTo={(id) => goTo(id)}/>
            <Features />
            <FeatureStats />
            <TwoTrendingPreviewCardsWithImage/>
            <DownloadApp />
            <ProfileThreeColGridCards/>
            <ThreeColumnWithProfileImage />
            <FAQ />
            <ContactUsForm contact={contact}/>
            <Footer />
          </AnimationRevealPage>
        </Route>
      </Switch>
    </Router>
  );
}