import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { SectionDescription } from "components/misc/Typography";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw(SectionDescription)`mt-4 font-semibold text-gray-600 text-center max-w-3xl`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-3/4 lg:w-10/12 xl:w-1/6 flex-shrink-0 h-80 md:h-72 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        "LOCKERS_P.png",

      title: "It all starts with great designs",
      description:
        "We take pride in our tailored approach to locker design, where we meticulously craft specifications to suit the unique needs of each client. From functional requirements to technical intricacies, electrical considerations, and maintenance specifications, we leave no stone unturned to ensure that our lockers meet the highest standards of design excellence. By prioritizing client needs and adhering to meticulous attention to detail, we deliver lockers that seamlessly blend functionality with innovation, setting the benchmark for superior design in the industry.",
    },

    {
      imageSrc:
        "LOCKERS_B.png",
      title: "Manufacturing in diverse materials, shapes and colors",
      description:
        "we specialize in crafting lockers using a variety of materials such as phenolic, wood, and metal. Whether it's the timeless elegance of wood, the durability of metal, or the versatility of phenolic, we ensure that each material is expertly crafted to meet our clients' unique specifications.",
    },

    {
      imageSrc:
        "LOCKERS_C.png",
      title: "MyLock's Control Unit",
      description:
        "We've developed a sophisticated Control Unit system comprised of multiple devices, enabling seamless and remote management of all lockers with unparalleled synchronicity. Equipped with essential peripherals, our system supports various authentication methods, including QR codes, RFID, PIN codes, fingerprint recognition, and our proprietary mobile application. This comprehensive approach ensures efficient and secure access control, empowering users with flexibility and convenience while maintaining the highest standards of security.",
    }
  ];

  return (
    <Container id="FullSolution">
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>We build <span tw="text-primary-500">entire solutions </span>of smart lockers</HeadingTitle>
          <HeadingDescription>
            We craft complete solutions from start to finish. Our journey begins with visionary industrial design, leading to precision manufacturing and expert electronic assembly. Finally, our cutting-edge software brings it all together, ensuring seamless operation and unmatched user experience. From concept to execution, we deliver innovation at every step.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>

              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
