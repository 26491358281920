import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

const Container = tw(ContainerBase)`my-8 lg:my-10 bg-primary-900 text-gray-100 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`text-gray-400 text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-between mx-auto`
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`
const StatKey = tw.div`text-xl font-medium`
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`

export default ({
  subheading = "",
  heading = (
    <>
      More than <span tw="text-secondary-500"> 2.000.000 uses </span>and growing. 
      <br/>
      Thousands of daily opereations accross europe.
    </>
  ),
  description = "Through our advanced serverless software, we ensure high scalability and availability of operations, allowing real-time insights into service status and usage. Our remote management portal provides usage statistics for each MyLock station of lockers. This empowers our clients to make strategic decisions such as service expansion, monetization, event organization, or targeted advertising.",
  stats = [
    {
      key: "Door openings",
      value: "+2Mill",
    },
    {
      key: "Active Users",
      value: "+10k",
    },   
     {
      key: "Lockers online",
      value: "+2k",
    },
    {
      key: "Countries",
      value: "+6",
    }, 
    {
      key: "API's",
      value: "+2",
    },
  ]
}) => {
  return (
    <Container id="Stats">
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              <StatValue>{stat.value}</StatValue>
              <StatKey>{stat.key}</StatKey>
            </Stat>
          ))}
        </StatsContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
